@use 'styles/includes' as *;

.LinkListLarge {
    $root: &;

    padding: 7.2rem;
    min-height: 100vh;
    background-color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &__Title {
        margin: 0 0 2rem;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.8rem;
        line-height: 100%;
        color: $white;
        text-transform: uppercase;
        border-bottom: 0.2rem solid $white;
        display: inline-block;
        z-index: -1;
    }

    &__List {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__Item {
        padding: 0.5rem 0;
        display: inline-block;
    }

    &__Link {
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 5.8rem;
        line-height: 100%;
        letter-spacing: -0.12rem;
        opacity: 1;
        color: $white;
        transition: opacity 0.3s ease;

        @include media(s) {
            font-size: 7.2rem;
        }

        @include media(m) {
            font-size: 10rem;
        }

        @include media(xxl) {
            font-size: 16rem;
        }

        &--InActive {
            opacity: 0.2;
        }
    }

    &__Image {
        aspect-ratio: 1/1;
        width: 40%;
        max-width: 80rem;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
        transform: scale(1);
        scale: none;
        transition:
            transform 0.2s linear,
            opacity 0.2s linear,
            visibility 0.2s linear;
        pointer-events: none;
        display: none;

        @include media(m) {
            display: block;
        }

        &--Visible {
            opacity: 1;
            visibility: visible;
            transform: scale(1.1, 1.1);
        }
    }
}
